import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import Button from '@mui/material/Button/Button';
import Typography from '@mui/material/Typography/Typography';
import Grid from '@mui/material/Grid/Grid';
import { GatsbyImage } from 'gatsby-plugin-image';
import DescriptionCta from 'components/molecules/DescriptionCta/DescriptionCta';
import CardProduct from 'components/organisms/CardProduct/CardProduct';

const useStyles = makeStyles((theme) => ({
  cardProduct: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.spacing(1),
    boxShadow: 'none',
    background: 'transparent',
    paddingBottom: theme.spacing(2),
    '& .card-product__content': {
      padding: theme.spacing(4),
      zIndex: 1,
      background: theme.palette.background.paper,
      width: '90%',
      margin: '0 auto',
      marginTop: theme.spacing(-6),
      borderRadius: theme.spacing(1),
      boxShadow: '0 3px 10px 2px rgba(0, 0, 0, 0.1)',
    },
    '& .card-product__media': {
      minHeight: 300,
      '& img': {
        transitionDuration: '.7s',
        transform: 'scale(1.0)',
      },
    },
    '&:hover': {
      '& .card-product__media img': {
        transform: 'scale(1.2)',
      },
    },
  },
  image: {
    objectFit: 'cover',
  },
  blogTitle: {
    fontWeight: 700,
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tag: {
    padding: theme.spacing(1 / 2, 1),
    borderRadius: theme.spacing(1 / 2),
    background: theme.palette.primary.main,
    color: 'white',
    margin: theme.spacing(0, 1, 1, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 2, 2, 0),
    },
  },
  author: {
    margin: theme.spacing(1, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2, 0),
    },
  },
  title: {
    fontWeight: 'bold',
  },
  descriptionCta: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
}));

const FeaturedArticles = (props) => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const BlogMediaContent = (props) => {
    return <GatsbyImage image={props} className={classes.image} alt="About" />;
  };

  const BlogContent = (props) => (
    <div>
      <div className={classes.tags}>
        {props.tags.map((item, index) => (
          <Typography variant="caption" className={classes.tag} key={index}>
            {item.title}
          </Typography>
        ))}
      </div>
      <Typography
        variant="h6"
        color="textPrimary"
        className={classes.blogTitle}
      >
        {props.title}
      </Typography>
      <Typography
        variant="body2"
        color="textPrimary"
        className={classes.author}
      >
        <i>
          {props.author} - {props.date}
        </i>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        {props.subtitle}
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        size="large"
        className={classes.button}
        href={props.readMore}
      >
        Read more
      </Button>
    </div>
  );
  return (
    <div className={className} {...rest}>
      <DescriptionCta
        title="Featured articles"
        subtitle="Latest updates from VRGeoscience."
        // primaryCta={
        //   <Button variant="outlined" color="primary" size="large" href={props.readMore}>
        //     View more
        //   </Button>
        // }
        primaryCta={<Fragment></Fragment>}
        align={'left'}
        titleProps={{
          variant: 'h4',
          color: 'textPrimary',
          className: classes.title,
        }}
        subtitleProps={{
          color: 'textPrimary',
          noWrap: false,
        }}
        className={classes.descriptionCta}
        data-aos="fade-up"
      />
      <Grid container spacing={isMd ? 4 : 2}>
        {data.map((item, index) => {
          if (item.featured && !item.archive)
            return (
              <Grid item xs={12} sm={6} key={index} data-aos="fade-up">
                <CardProduct
                  className={classes.cardProduct}
                  mediaContent={
                    <BlogMediaContent
                      {...item.mainImage.asset.gatsbyImageData}
                      alt={item.excerpt}
                    />
                  }
                  cardContent={
                    <BlogContent
                      title={item.title}
                      // subtitle={item.subtitle}
                      author={item.author.name}
                      date={item.date}
                      tags={item.categories}
                      readMore={`/${item._rawSlug.current}`}
                    />
                  }
                />
              </Grid>
            );
          return null;
        })}
      </Grid>
    </div>
  );
};

FeaturedArticles.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default FeaturedArticles;

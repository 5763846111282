import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import Button from '@mui/material/Button/Button';
import Typography from '@mui/material/Typography/Typography';
import Grid from '@mui/material/Grid/Grid';
import { GatsbyImage } from 'gatsby-plugin-image';
import DescriptionCta from 'components/molecules/DescriptionCta/DescriptionCta';
import CardProduct from 'components/organisms/CardProduct/CardProduct';

const useStyles = makeStyles((theme) => ({
  cardProduct: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.spacing(1),
    '& .card-product__content': {
      padding: theme.spacing(2),
    },
    '& .card-product__media': {
      minHeight: 300,
    },
  },
  image: {
    objectFit: 'cover',
  },
  blogTitle: {
    fontWeight: 700,
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  tag: {
    fontWeight: 700,
    margin: theme.spacing(0, 1, 1, 0),
  },
  author: {
    margin: theme.spacing(1, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2, 0),
    },
  },
  title: {
    fontWeight: 'bold',
  },
  descriptionCta: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
}));

const LatestStories = (props) => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const BlogMediaContent = (props) => (
    <GatsbyImage image={props} className={classes.image} alt={'About'} />
  );

  const BlogContent = (props) => (
    <div>
      <div className={classes.tags}>
        {props.tags.map((item, index) => (
          <Typography
            variant="overline"
            color="primary"
            className={classes.tag}
            key={index}
          >
            {item.title}
          </Typography>
        ))}
      </div>
      <Typography
        variant="h6"
        color="textPrimary"
        className={classes.blogTitle}
        align="center"
      >
        {props.title}
      </Typography>
      <Typography
        variant="body2"
        color="textPrimary"
        className={classes.author}
        align="center"
      >
        <i>
          {props.author} - {props.date}
        </i>
      </Typography>
      <Typography variant="body1" color="textPrimary" align="center">
        {props.subtitle}
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        size="large"
        className={classes.button}
        href={props.readMore}
      >
        Read more
      </Button>
    </div>
  );
  return (
    <div className={className} {...rest}>
      <DescriptionCta
        title="Latest stories"
        // primaryCta={
        //   <Button variant="outlined" color="primary" size="large">
        //     View all
        //   </Button>
        // }
        primaryCta={<Fragment></Fragment>}
        align={'left'}
        titleProps={{
          variant: 'h4',
          color: 'textPrimary',
          className: classes.title,
        }}
        className={classes.descriptionCta}
        data-aos="fade-up"
      />
      <Grid container spacing={2}>
        {data.map((item, index) => {
          if (item.featured == true) return null;
          if (item.archive) return null;
          return (
            <Grid item xs={12} sm={4} key={index} data-aos="fade-up">
              <CardProduct
                withShadow
                liftUp
                className={classes.cardProduct}
                mediaContent={
                  <BlogMediaContent
                    {...item.mainImage.asset.gatsbyImageData}
                    alt={item.title}
                  />
                }
                cardContent={
                  <BlogContent
                    // title={item.title}
                    // subtitle={item.subtitle}
                    // author={item.author}
                    // date={item.date}
                    // tags={item.tags}
                    // readMore={item.readMore}
                    title={item.title}
                    author={item.author.name}
                    date={item.date}
                    tags={item.categories}
                    readMore={`/${item._rawSlug.current}`}
                  />
                }
              />
            </Grid>
          );
          return null;
        })}
      </Grid>
    </div>
  );
};

LatestStories.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default LatestStories;

import React from 'react';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid/Grid';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import Section from 'components/organisms/Section/Section';
import SectionAlternate from 'components/organisms/SectionAlternate/SectionAlternate';
import { useStaticQuery, graphql } from 'gatsby';

import {
  Archive,
  FeaturedArticles,
  Hero,
  LatestStories,
  Tags,
} from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sidebarNewsletter: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
    },
  },
  footerNewsletterSection: {
    background: theme.palette.primary.dark,
  },
}));
const BlogNewsroom = () => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const content = useStaticQuery(graphql`
    query {
      posts: allSanityPost(sort: { fields: _createdAt, order: DESC }) {
        nodes {
          title
          excerpt
          _rawSlug
          featured
          _createdAt
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, width: 600)
            }
          }
          categories {
            title
          }
          author {
            name
            image {
              asset {
                gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
              }
            }
          }
          archive
        }
      }
      category: allSanityCategory {
        nodes {
          title
        }
      }
    }
  `);
  const data = content.posts.nodes;
  const category = content.category.nodes;

  return (
    <div className={classes.root}>
      <Hero />
      <SectionAlternate>
        <FeaturedArticles data={data} />
      </SectionAlternate>
      <Section>
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12} md={12}>
            <LatestStories data={data} />
          </Grid>
        </Grid>
      </Section>
      <Section>
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12} md={8}>
            <Archive data={data} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Tags data={category} />
          </Grid>
        </Grid>
      </Section>
    </div>
  );
};

export default BlogNewsroom;

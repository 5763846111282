/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import BlogNewsroom from 'views/BlogNewsroom';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';

import SEO from '../src/components/SEO';

export const Head = () => (
  <SEO description="New and updates on digital outcrop modelling from VRGeoscience Limited's blog" />
);
const BlogNewsroomPage = () => {
  return <WithLayout component={BlogNewsroom} layout={Main} />;
};

export default BlogNewsroomPage;

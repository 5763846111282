import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography/Typography';
import Grid from '@mui/material/Grid/Grid';
import { GatsbyImage } from 'gatsby-plugin-image';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';
import CardProduct from 'components/organisms/CardProduct/CardProduct';

const useStyles = makeStyles((theme) => ({
  cardProduct: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    boxShadow: 'none',
    borderRadius: 0,
    '& .card-product__content': {
      padding: theme.spacing(2, 0, 0, 0),
    },
    '& .card-product__media': {
      height: 160,
      '& img': {
        height: 160,
      },
    },
  },
  image: {
    objectFit: 'cover',
  },
  blogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  blogTitle: {
    fontWeight: 700,
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
}));

const Archive = (props) => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const BlogMediaContent = (props) => {
    return (
      <GatsbyImage image={props} className={classes.image} alt={props.alt} />
    );
  };

  const BlogContent = (props) => (
    <div className={classes.blogContent}>
      <Typography
        variant="body2"
        color="textPrimary"
        className={classes.blogTitle}
        gutterBottom
      >
        {props.title}
      </Typography>
      <Typography variant="caption" color="textPrimary">
        <i>
          {props.author} - {props.date}
        </i>
      </Typography>
    </div>
  );

  return (
    <div className={className} {...rest}>
      <SectionHeader title="Archived news" data-aos="fade-up" align="left" />
      <Grid container spacing={2}>
        {data.map(
          (item, index) =>
            item.archive === true && (
              <Grid item xs={6} md={3} key={index} data-aos="fade-up">
                <CardProduct
                  className={classes.cardProduct}
                  mediaContent={
                    <BlogMediaContent
                      {...item.mainImage.asset.gatsbyImageData}
                      alt={item.title}
                    />
                  }
                  cardContent={
                    <BlogContent
                      title={item.title}
                      author={item.author.name}
                      date={item.date}
                      tags={item.categories}
                      readMore={`/${item._rawSlug.current}`}
                    />
                  }
                />
              </Grid>
            ),
        )}
      </Grid>
    </div>
  );
};

Archive.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Archive;
